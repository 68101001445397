<template>
  <div :class="{ itemm: isSelected }" v-if="seleccion && isSelected || !isSelected && !seleccion"
  >
    <div class="hoverr hoverr-2 text-white rounded">
      <div class="pointer justify-content-end">
        <b-icon @click="$emit('eliminarImagen', {src:imageSrc, index:index})" v-b-tooltip.hover="'Eliminar imagen'" scale="1" variant="danger" class="icons" icon="trash-fill"></b-icon>
      </div>
      <div @click="$emit('selectedImage', { url: imageSrc, isSelected: isSelected })">
        <img
          style="width: 100%"
          v-if="isImage == 'image'"
          :src="baseApi+'/img/minGallery/' + imageSrc"
          :alt="imageDescription"
        />
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "cardViewSelector",
  props: ["imageSrc", "imageDescription", "index", "imagesSelected", "position","seleccion"],
  data(){
    return{
      baseApi: location.protocol + "//" + location.hostname,
    }
  },
  components: {},
  methods: {},
  computed: {
    isSelected() {
      if (this.imagesSelected) {
        return this.imagesSelected.find(
          (value) => value.urlImage == this.imageSrc
        );
      }else{
        return false;
      }
    },
    isImage() {
      if (this.imageSrc.includes("http")) {
        return "video";
      } else {
        return "image";
      }
    },
  },
};
</script>
<style>
.hoverr .pointer svg{
  margin-top: 0.5rem;
  right: 0.5rem;
  position: absolute;
  z-index: 5;
}

.itemm {
  padding: 2%;
  background: forestgreen;
}
div img:hover {
  cursor: pointer;
}
span,
h4,
p {
  user-select: none;
}
.hoverr {
  overflow: hidden;
  position: relative;
  padding-bottom: 60%;
}

.hoverr-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 90;
  transition: all 0.4s;
}

.hoverr img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.3s;
}

.hoverr-content {
  position: relative;
  z-index: 99;
}

.hoverr-2 .hoverr-overlay {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.1));
}

.hoverr-2-title {
  position: absolute;
  top: 50%;
  left: 0;
  text-align: center;
  width: 100%;
  z-index: 99;
  transition: all 0.3s;
}

.hoverr-2-description {
  width: 100%;
  position: absolute;
  bottom: 0;
  opacity: 1;
  left: 0;
  text-align: center;
  z-index: 99;
  transition: all 0.3s;
}
/* .cardViewconf :hoverr {
  cursor: pointer;
} */
@media (max-width: 40rem) {
  .cardViewconf {
    margin-bottom: 10px;
  }
}
</style>
