<template>
  <div class="bodyNews">
    <div class="NewsCards" v-if="lista.length > 0">
      <template v-for="(item, index) in lista">
        <cardNews
          :imageSrc="item.imagenportada"
          :newTitle="item.titulo"
          :cuerpoNoticia="item.prenoticia"
          :fechaPublicacion="item.fechapublicacion"
          :key="index"
          :idnoticia="item.id"
          :idGallery="item.idgaleria"
          @getIdNews="getId"
        />
      </template>
    </div>
  </div>
</template>

<script>
import cardNews from "@/components/CardNoticias.vue";
import axios from "axios";

export default {
  metaInfo() {
    return {
      title: "Parroquia Castrense de la Armada Nuestra Señora del Carmen - Noticias",
      meta: [
        {
          name: "description",
          content: "Noticias sobre la Parroquia Castrense de la Armada Nuestra Señora del Carmen",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: `https://parroquiaarmada.com${this.$route.fullPath}`,
        },
      ],
    };
  },
  name: "vistaNoticias",
  props: [],
  components: {
    cardNews,
  },
  data() {
    return {
      lista: [],
      baseApi: location.protocol + "//" + location.hostname,
    };
  },
  methods: {
    getId(value) {
      this.$router.push({
        name: "noticia",
        query: { id: value.id },
      });
    },
  },
  mounted() {
    axios
      .get(`${this.baseApi}/api_armadamadrid/noticias?totalNoticias`)
      .then((data) => {
        this.lista = data.data;
        this.lista = this.lista.reverse();
      })
      .catch((erro) => {
        console.log(erro);
      });
    if (document.getElementsByClassName("cabecera")) {
      document.getElementById("header-logo").style.width = "400px";
      document.getElementsByClassName("cabecera")[0].style.background =
        "rgb(27,90,140,1)";
    }
  },
};
</script>
<style scoped>
.NewsCards {
  margin-left: 50px;
  margin-right: 50px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  grid-auto-rows: auto;
}
.bodyNews {
  padding-top: 150px;
  min-height: calc(100vh);
}
@media screen and (max-width: 600px) {
  .NewsCards {
    margin-left: 20%;
    margin-right: 20%;
    display: block;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
    grid-auto-rows: auto;
  }
}
@media screen and (min-width: 600px) and (max-width: 900px) {
  .NewsCards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    grid-auto-rows: auto;
  }
}
@media screen and (min-width: 900px) and (max-width: 1200px) {
  .NewsCards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    grid-auto-rows: auto;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1750px) {
  .NewsCards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    grid-auto-rows: auto;
  }
}
@media screen and (min-width: 1750px) {
  .NewsCards {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 10px;
    grid-auto-rows: auto;
  }
}
</style>
