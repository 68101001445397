<template>
  <b-card class="cards_last_news" v-if="horarios != undefined">
    <article class="blog-card article-horarios">
      <b-icon scale="4" class="icons" icon="clock"></b-icon>
      <div v-for="(item, index) in horarios" :key="index">
        <!-- titulo principal -->
        <h4 class="titulo-principal">
          <span v-html="item.titulo"></span>
        </h4>
        <!-- Recorre los distintos eventos, misas, bautizos...-->
        <div
          v-for="(evento, indexEvento) in item.element"
          :key="indexEvento"
          style="text-align: left"
        >
          <h5 v-html="evento.evento"></h5>
          <!-- Se recorre cada evento -->
          <p
            v-for="(itemInt, indexdata) in evento.values"
            :key="indexdata"
            v-html="obtenerHorariosValue(itemInt)"
          ></p>
        </div>
      </div>
    </article>
  </b-card>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      baseApi: location.protocol + "//" + location.hostname,
      horarios: undefined,
    };
  },
  name: "horariosItem",
  props: [],
  components: {},
  mounted() {
    this.obtenerHorarios();
  },
  methods: {
    obtenerHorariosValue(value) {
      var dias = value[0];
      if (value[1] != "") {
        dias += ": " + value[1];
      }
      return dias;
    },
    obtenerHorarios() {
      axios({
        method: "GET",
        url: `${this.baseApi}/api_armadamadrid/horarios`,
        headers: { "Content-Type": "application/json" },
      })
        .then((data) => {
          this.horarios = data.data.filter((element) => element.activo);
        })
        .catch((erro) => {
          console.log(erro);
        });
    },
  },
  computed: {},
};
</script>
<style scoped>
p {
  padding: 0;
  margin: 0;
}

h5 {
  margin-top: 30px;
  font-weight: bold;
}

.titulo-principal {
  padding-top: 50px;
}

.article-horarios {
  padding: 30px;
  padding-top: 50px;
}
.autor {
  text-align: end;
  font-size: 12px;
  padding-right: 10px;
  color: slategrey;
}
.card-body {
  color: #1b5a8c;
  padding: 0 !important;
}
h3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

@import url("https://fonts.googleapis.com/css?family=Roboto:400,700");
* {
  box-sizing: border-box;
}

#root {
  width: 30rem;
  height: 13.625rem;
}

.blog-card {
  background: #e3e3e39e;
  flex-direction: row;
  /* background: #fff; */
  box-shadow: 0 0.1875rem 1.5rem rgba(0, 0, 0, 0.2);
  border-radius: 0.375rem;
  overflow: hidden;
}

@supports (display: grid) {
  #root {
    grid-area: main;
    align-self: center;
    justify-self: center;
  }
}
</style>
