import Vue from 'vue';
import Vuex from 'vuex';
import axios from "axios";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    evangelioDia: "",
    lecturaDia:"",
    baseApi: location.protocol + "//" + location.hostname,
    allImagenes: [],
    allGalerias: [],
    allNoticias: [],
    allNoticiasTotal: []
  },
  getters: {
    getEvangelioDia(state){
      return state.evangelioDia;
    },
    getLecturaDia(state){
      return state.lecturaDia;
    },
    getAllImages(state) {
      return state.allImagenes.reverse();
    },
  },
  mutations: {
    changeEvangelioDia(state, data){
      state.evangelioDia = data;
    },
    changeLecturaDia(state, data){
      state.lecturaDia = data;
    },
    inicialTodasGalerias(state) {
      axios({
        method: "GET",
        url: `${state.baseApi}/api_armadamadrid/galeria`,
        headers: { "Content-Type": "application/json" },
      })
        .then((data) => {
          state.allGalerias = data.data;
        })
        .catch((erro) => {
          console.log(erro);
        });
    },
    initialAllImages(state) {
      axios({
        method: "GET",
        url: `${state.baseApi}/api_armadamadrid/galeria?todasImagenes`,
        headers: { "Content-Type": "application/json" },
      })
        .then((data) => {
          state.allImagenes = data.data;
        })
        .catch((erro) => {
          console.log(erro);
        });
    },
  },
  actions: {
    changeAllImages(context) {
      context.commit("initialAllImages");
    },
    initialDataChange(context) {
      context.commit("initialAllImages");
    },
  },
  modules: {
  }
})
