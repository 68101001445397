<template>
  <div class="content-nav principalColor">
    <b-navbar toggleable="lg" type="dark">
      <b-navbar-brand to="./">
        <img
          id="header-logo"
          :src="require('@/assets/logo 2.2 sin fondo.png')"
          alt="logo Parroquia de la armada Nuestra señora del carmen"
        />
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav class="cabecera-posicion">
        <b-navbar-nav>
          <b-nav-item to="Multimedia">Galeria</b-nav-item>
          <b-nav-item to="vistaNoticias">Noticias</b-nav-item>
          <b-nav-item-dropdown text="Caritas" right>
            <b-dropdown-item to="CaritasParroquial"
              >Cáritas Parroquial</b-dropdown-item
            >
            <b-dropdown-item to="CaritasParroquialNoticias"
              >Noticias Cáritas Parroquial</b-dropdown-item
            >
            <b-dropdown-item to="CaritasProyectos">Proyectos</b-dropdown-item>
            <b-dropdown-item
              href="https://www.caritas.es/castrense/"
              target="bank"
              >Caritas Castrense</b-dropdown-item
            >
          </b-nav-item-dropdown>
          <b-nav-item @click="$bvModal.show('evangelioDia')"
            >Lecturas</b-nav-item
          >
          <b-nav-item @click="$bvModal.show('contact')">Contacto</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <!-- <massTime modalId="massTime" /> -->
    <contact modalId="contact" />
    <evangelio-dia />
  </div>
</template>

<script lang="ts">
//import massTime from "./massTime.vue";
import contact from "@/components/ContactoModal.vue";
import evangelioDia from "@/components/EvangelioDia.vue";

export default {
  components: {
    // massTime,
    contact,
    evangelioDia,
  },
  name: "CabeceraW",
  props: [],
  data() {
    return {};
  },
  mounted() {
    window.addEventListener("scroll", this.scrollFunction);
    window.addEventListener("resize", this.scrollFunction);
    window.location;
  },
  computed: {},

  methods: {
    unmounted() {
      window.removeEventListener("scroll", this.scrollFunction);
    },
    beforeDestroy() {
      window.removeEventListener("scroll", this.scrollFunction);
    },
    scrollFunction() {
      console.log("scroolFunction");
      if (window.location.pathname == "/") {
        if (
          document.body.scrollTop > 100 ||
          document.documentElement.scrollTop > 100 ||
          window.innerWidth <= 990
        ) {
          if (document.getElementsByClassName("cabecera")) {
            document.getElementById("header-logo").style.width = "400px";
            document.getElementsByClassName("cabecera")[0].style.background =
              "rgb(27,90,140,1)";
          }
        } else {
          if (document.getElementsByClassName("cabecera")) {
            document.getElementById("header-logo").style.width = "500px";
            document.getElementsByClassName("cabecera")[0].style.background =
              "rgb(27,90,140,0.6)";
          }
        }
      } else {
        if (document.getElementsByClassName("cabecera")) {
          document.getElementById("header-logo").style.width = "400px";
          document.getElementsByClassName("cabecera")[0].style.background =
            "rgb(27,90,140,1)";
        }
      }
    },
  },
};
</script>

<style>
@import "@/assets/styles/principal.css";
.nav-link {
  font-size: 20px;
}
.content-nav {
  width: 100%;
  z-index: 1;
}

@media screen and (min-width: 991px) {
  #header-logo {
    width: 500px;
  }
  .navbar-expand-lg {
    display: block !important;
    transition: 0.6s;
  }
}
@media screen and (min-width: 457px) and (max-width: 990px) {
  #header-logo {
    width: 400px;
  }
}
@media screen and (max-width: 456px) {
  #header-logo {
    width: 300px;
  }
}
.navbar-brand {
  margin: 0 auto !important;
}
.cabecera {
  transition: 0.6s;
}
#header-logo {
  transition: 0.9s;
}
</style>
