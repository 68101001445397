<template>
  <b-col>
    <div>
      <galeryPhoto id="galeryPhoto" />
    </div>
    <div class="sectionCard">
      <img
        class="iconScroll"
        :src="'https://santodomingocartagena.com/img/icon/scroll-down-icon.gif'"
        alt=""
      />
    </div>
    <div class="bodyPrincipalPage">
      <div class="doble-columnas">
        <b-col>
          <b-card class="enlaces">
            <h2 id="titleLastNews">Ultimas Noticias</h2>
            <div v-if="noticiasCargadas" class="lastNewsPp">
              <ultimas-noticias
                v-for="(item, index) in news.slice(0, width)"
                :imageSrc="item.imagenportada"
                :newTitle="item.titulo"
                :cuerpoNoticia="item.prenoticia"
                :fechaPublicacion="item.fechapublicacion"
                :key="index"
                :idnoticia="
                  item.paginaPrincipal ? 'caritas-' + item.id : item.id
                "
                :idGallery="item.idgaleria"
                @getIdNews="getId"
              />
            </div>
            <div v-else>
              <b-spinner></b-spinner>
            </div>
          </b-card>
        </b-col>
        <b-col>
          <b-card class="enlaces">
            <horariosItem />
          </b-card>
        </b-col>
      </div>
      <b-card class="d-flex">
        <div class="d-flex">
          <iframe
            id="iframe-map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3035.1893854866253!2d-3.6715834234675913!3d40.471075052329155!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd422936dc4d0e05%3A0xa28e33f096ae8ed!2sParroquia%20de%20la%20Armada!5e0!3m2!1ses!2ses!4v1711446262805!5m2!1ses!2ses"
            width="100%"
            height="300"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          />
        </div>
      </b-card>
    </div>
  </b-col>
</template>

<script>
import galeryPhoto from "@/components/GaleryPhoto.vue";
import ultimasNoticias from "@/views/website/paginaPrincipal/UltimasNoticias.vue";
import axios from "axios";
import horariosItem from "@/components/HorariosItem.vue";

export default {
  data() {
    return {
      width: 6,
      news: [],
      baseApi: location.protocol + "//" + location.hostname,
      enlacesInteres: [],
    };
  },
  metaInfo() {
    return {
      title: "Parroquia Castrense de la Armada Nuestra Señora del Carmen",
      meta: [
        {
          name: "description",
          content:
            "Informacion sobre la Parroquia de la Armada de Madrid.",
        },
        {
          name: "keywords",
          content:
            "Nuestra Señora del Carmen Madrid, iglesia de la armada Nuestra Señora del Carmen, parroquia de la armada Nuestra Señora del Carmen, parroquia Nuestra Señora del Carmen, misas Madrid, iglesia Nuestra Señora del Carmen, misas de la armada Madrid",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://parroquiaarmada.com",
        },
      ],
    };
  },
  name: "HomePage",
  props: {
    msg: String,
  },
  components: {
    horariosItem,
    galeryPhoto,
    ultimasNoticias,
  },
  computed: {
    noticiasCargadas() {
      return this.news.length > 0 ? true : false;
    },
  },
  methods: {
    onResize() {
      if (
        (window.innerWidth >= 600 && window.innerWidth < 950) ||
        (window.innerWidth >= 950 && window.innerWidth < 1200)
      ) {
        this.width = 6;
      } else if (window.innerWidth >= 1200 && window.innerWidth <= 1500) {
        this.width = 6;
      } else if (window.innerWidth < 600) {
        this.width = 4;
      } else {
        this.width = 8;
      }
    },
    obtenerNoticias() {
      axios({
        method: "GET",
        url: `${this.baseApi}/api_armadamadrid/noticias?ultimasNoticias`,
        headers: { "Content-Type": "application/json" },
      })
        .then((data) => {
          console.log("");
          this.news = data.data.sort(function (a, b) {
            if (a.fechapublicacion > b.fechapublicacion) {
              return -1;
            }
            if (a.fechapublicacion < b.fechapublicacion) {
              return 1;
            }
            return 0;
          });
        })
        .catch((erro) => {
          console.log(erro);
        });
    },
    getId(value) {
      this.$router.push({
        name: "noticia",
        query: { id: value.id },
      });
    },
  },
  mounted() {
    console.log("paginaPrincipal");
    this.$emit("datosCargados", false);
    this.obtenerNoticias();
    this.onResize();
  },
};
</script>

<style scoped>
.enlaces {
  min-height: 97.5%;
  background: rgb(254, 255, 254, 0.2);
  margin: 15px;
}

p {
  max-width: 1200px;
  display: inline-flex;
  font-style: italic;
  color: black;
}
.lastNewsPp {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  transition: 2s;
}
.historyParr {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  background: rgba(240, 255, 240, 0.8);
  padding-top: 1%;
  padding-bottom: 1%;
  text-align: center;
}
.iconScroll {
  filter: brightness(1.1);
  mix-blend-mode: multiply;
  position: absolute;
  bottom: 0;
  width: 100px;
  margin-left: 48%;
  left: 0;
}
.sectionCard {
  height: calc(90vh);
  position: relative;
}
#galeryPhoto {
  z-index: -1;
  position: fixed;
  width: 100%;
}
.lastNews {
  height: 100%;
  padding-top: 15px;
}
.cardsHome {
  height: 650px;
}
#titleLastNews {
  border-bottom: 1px solid;
  text-align: initial;
}
.bodyPrincipalPage {
  background: rgb(254, 255, 254, 0.7);
}
@media (max-width: 990px) {
  .doble-columnas {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    transition: 2s;
  }
}
@media (min-width: 990px) {
  .doble-columnas {
    display: grid;
    grid-template-columns: 1fr 350px;
    transition: 2s;
  }
}
@media (max-width: 600px) {
  .iconScroll {
    display: none;
  }
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }
  .lastNewsPp {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
  #iframe-map {
    width: 100%;
  }
}
@media (min-width: 600px) and (max-width: 950px) {
  .iconScroll {
    display: none;
  }
  .lastNewsPp {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }
}
@media (min-width: 950px) and (max-width: 1200px) {
  .lastNewsPp {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1200px) and (max-width: 1500px) {
  .lastNewsPp {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}
</style>
