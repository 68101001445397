<template>
  <div class="wrapperer">
    <div class="form-signin">
      <h1> <img
            id="header-logo"
            :src="require('@/assets/logo 2.2-letras negras.png')"
            alt="logo Parroquia de la armada Nuestra señora del carmen"
            style="width: 100%;"
          /></h1>
      <h2 class="form-signin-heading">Iniciar Sesión</h2>
      <b-form-input
        type="text"
        class="form-control"
        placeholder="Usuario"
        v-model="user"
        :state="validation(user)"
        ></b-form-input>
      <b-form-input
        type="password"
        class="form-control"
        placeholder="Contraseña"
        v-model="password"
        :state="validation(password)"
      ></b-form-input>
      <button :disabled="enableButton" class="btn btn-lg btn-primary btn-block" @click="login">
        Iniciar Sesion
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "loginWeb",
  components: {
  },
  data() {
    return {
      user: "",
      password: "",
      token: undefined,
      baseApi: location.protocol + "//" + location.hostname,
      enableButton:true,
      dataSesion:{
        ip:'',
        pais:'',
        ciudad:'',
        region:'',
        gps:''
      },

    };
  },
  watch:{
    user(){
      if(this.user.length>0 && this.password.length>0){
        this.enableButton = false;
      }else{
        this.enableButton = true;
      }
    },
    password(){
      if(this.user.length>0 && this.password.length>0){
        this.enableButton = false;
      }else{
        this.enableButton = true;
      }
    },

  },
  mounted(){
    axios({
      method: "GET",
        url: `https://api.ipify.org/?format=json`,
    })
    .then((element)=>{
      this.dataSesion.ip= element.data.ip;
      axios({
        method:"GET",
        url:`https://ipwhois.app/json/${element.data.ip}?lang=es`
      })
      .then((data)=>{
        this.dataSesion.pais= data.data.country;
        this.dataSesion.ciudad= data.data.city;
        this.dataSesion.region= data.data.region;
        this.dataSesion.gps= data.data.latitude +" - "+ data.data.longitude;
      })
    })
  },
  computed: {},
  methods: {
    validation(element){
      return element.length>0;
    },
    login() {
        axios({
        method: "POST",
        url: `${this.baseApi}/api_armadamadrid/auth`,
        headers: {},
        data: {
          usuario: this.user,
          pass: this.password,
          datasesion:this.dataSesion
        }
        })
        .then((value) => {
          this.token = value.data.result.token;
          let path = window.location.hostname;
          if (!this.$cookies.get("user_sessionSD") && this.token) {
            this.$cookies.set("user_sessionSD", this.token, 60 * 60 * 2, path);
          }
          if (this.$cookies.get("user_sessionSD")) {
            this.$router.push({ name: "dashboard"});
          }
        })
        .catch(function (error){
          alert(error.response.data.result.error_msg);
        });
    },
  },
  created() {
    if (this.$cookies.get("user_sessionSD")) {
      this.$router.push({ name: "dashboard" });
    }
  },
};
</script>

<style>
body {
  background: #eee !important;
}

.wrapperer {
  height: calc(100vh);
  background: #1b5a8c;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.form-signin {
  max-width: 380px;
  padding: 15px 35px 45px;
  margin: 0 auto;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.form-signin .form-signin-heading,
.form-signin .checkbox {
  margin-bottom: 30px;
}
.form-signin .checkbox {
  font-weight: normal;
}
.form-signin .form-control {
  position: relative;
  font-size: 16px;
  height: auto;
  padding: 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="text"] {
  margin-bottom: -1px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 20px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>
