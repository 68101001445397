<template>
  <b-modal
    class="modalEvangelioDia"
    size="xl"
    id="evangelioDia"
    :hide-footer="true"
    :hide-header="false"
    @show="showModalEv"
  >
    <template v-slot:modal-header>
      <div class="d-flex">
        <img
          style="height: 60px"
          :src="require('@/assets/logo 2.2-letras negras.png')"
          alt="logo Parroquia de la armada Nuestra señora del carmen"
        />
      </div>
      <div class="pointer justify-self-center">
        <closeicon @click="closeModal()" />
      </div>
    </template>
    <div>
      <b-tabs content-class="mt-3" justified>
        <b-tab title="lectura del dia" id="leDia" active>
          <div style="max-height: 65vh;  overflow-y:scroll;" v-html="cuerpolecturas">
          </div>
        </b-tab>
        <b-tab title="Evangelio del dia" id="evDia" active>
          <div style="max-height: 65vh; overflow-y:scroll;" v-html="cuerpoEvangelio">
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </b-modal>
</template>

<script lang="ts">
import closeicon from "@/assets/components/close.vue";
import axios from "axios";

export default {
  name: "evangelioDia",

  components: {
    closeicon,
  },

  data() {
    return {
      baseApi: location.protocol + "//" + location.hostname,
    };
  },

  props: [],
  mounted() {
    this.getEvangelioDia();
  },
  computed: {
    getDay() {
      return new Date().getDate() < 10
        ? "0" + new Date().getDate()
        : new Date().getDate().toString();
    },
    getMonth() {
      return new Date().getMonth() + 1 < 10
        ? "0" + (new Date().getMonth() + 1)
        : new Date().getMonth() + 1;
    },
    getYear() {
      return new Date().getFullYear().toString();
    },

    cuerpolecturas() {
      return this.$store.getters.getLecturaDia.length >0
        ? this.$store.getters.getLecturaDia
        : "";
    },
    cuerpoEvangelio() {
      return this.$store.getters.getEvangelioDia.length >0
        ? this.$store.getters.getEvangelioDia
        : "";
    },
  },
  methods: {
    showModalEv() {
      console.log('evangelio');
      this.getEvangelioDia();
    },
    getEvangelioDia() {
      if (this.$store.getters.getEvangelioDia == "") {
        //api_armadamadrid/evangelioDia?fecha=2023/02/19
        axios({
          method: "GET",
          url: `${this.baseApi}/api_armadamadrid/evangelioDia?fecha=${this.getYear}/${this.getMonth}/${this.getDay}`,
        })
          .then((data) => {
            this.$store.commit("changeEvangelioDia", data.data.speech[0].vangeloText);
            this.$store.commit("changeLecturaDia", data.data.speech[0].letturaText);
          })
          .catch((erro) => {
            console.log(erro);
          });
      }
    },
    closeModal() {
      this.$bvModal.hide("evangelioDia");
    },
  },
};
</script>
<style scoped>
.d-flex{margin: 0 auto !important;
}
.pointer :hover {
  cursor: pointer;
}
.timeHour {
  padding: 0;
  margin: 0;
}
</style>
