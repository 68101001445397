<template>
  <div class="bodyMedia">
    <div class="wrapper" v-if="lista.length > 0">
      <template v-for="(item, index) in lista">
        <cardView
          :imageSrc="item.backImage"
          :imagetitle="item.titulo"
          :imageDescription="item.descripcion"
          :key="index"
          :idGallery="item.id"
          @getIdGalery="getId"
        />
      </template>
    </div>
  </div>
</template>

<script>
import cardView from "@/components/galeria/CardView.vue";
// import loadingWebSite from "../../src/components/loadingwebsite.vue";
import axios from "axios";

export default {
  name: "vistaGaleria",
  props: ["listMedia"],
  metaInfo() {
    return {
      title: "Parroquia de la Armada - Multimedia",
      meta: [
        {
          name: "description",
          content:
            "imagenes y videos sobre de actividades de la parroquia de la armada Nuestra Señora del Carmen - Madrid",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: `https://santodomingocartagena.com${this.$route.fullPath}`,
        },
      ],
    };
  },
  components: {
    // loadingWebSite,
    cardView,
  },
  data() {
    return {
      lista: [],
      baseApi: location.protocol + "//" + location.hostname,
    };
  },
  mounted() {
    axios
      .get(`${this.baseApi}/api_armadamadrid/galeria`)
      .then((data) => {
        this.lista = data.data.filter((element => element.id!=1));
        this.lista = this.lista.reverse();
      })
      .catch((erro) => {
        console.log(erro);
      });
  },
  methods: {
    getId(value) {
      let title = value.title.replaceAll(" ", "_");
      this.$router.push({
        name: "carrete",
        query: { id: value.id, title: title },
      });
    },
  },
  computed: {},
};
</script>
<style>
.wrapper {
  margin-left: 50px;
  margin-right: 50px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  grid-auto-rows: auto;
}
.bodyMedia {
  padding-top: 150px;
  min-height: calc(100vh);
}
@media screen and (max-width: 600px) {
  .wrapper {
    margin-left: 20%;
    margin-right: 20%;
    display: block;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
    grid-auto-rows: auto;
  }
}
@media screen and (min-width: 600px) and (max-width: 900px) {
  .wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    grid-auto-rows: auto;
  }
}
@media screen and (min-width: 900px) and (max-width: 1200px) {
  .wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    grid-auto-rows: auto;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1750px) {
  .wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    grid-auto-rows: auto;
  }
}
</style>
