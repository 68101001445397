var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.idGallery)?_c('div',{staticClass:"cardViewconfconf"},[_c('div',{staticClass:"hoverr hoverr-2 text-white rounded"},[(_vm.imageSrc.includes('img') && _vm.idGallery == 0)?_c('img',{staticStyle:{"width":"25%","margin-left":"37%"},attrs:{"src":_vm.imageSrc,"alt":_vm.imageDescription}}):_c('img',{staticStyle:{"width":"100%"},attrs:{"src":`${this.baseApi}/img/minGallery/` + _vm.imageSrc,"alt":_vm.imageDescription}}),_c('div',{staticClass:"hoverr-overlay"}),_c('div',{staticClass:"hoverr-2-content px-5 py-4"},[_c('h4',{staticClass:"hoverr-2-title text-uppercase font-weight-bold mb-0"},[_c('span',{staticClass:"font-weight-light"},[_vm._v(_vm._s(_vm.imagetitle)+" ")])]),(_vm.idGallery != 0 && _vm.idGallery != 1)?_c('p',{staticClass:"hoverr-2-description text-uppercase mb-0"},[_c('b-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Galeria de imagenes'),expression:"'Galeria de imagenes'",modifiers:{"hover":true}}],staticClass:"iconn",staticStyle:{"color":"blue"},attrs:{"icon":"images"},on:{"click":function($event){return _vm.$emit('iconClick', {
              id: _vm.idGallery,
              title: _vm.imagetitle,
              action: 'openGallery',
            })}}}),(_vm.idGallery != 2)?_c('b-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Eliminar Galeria'),expression:"'Eliminar Galeria'",modifiers:{"hover":true}}],staticClass:"iconn",staticStyle:{"color":"green"},attrs:{"icon":"trash"},on:{"click":function($event){return _vm.$emit('iconClick', { id: _vm.idGallery, action: 'deleteGallery' })}}}):_vm._e(),_c('b-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Editar Galeria'),expression:"'Editar Galeria'",modifiers:{"hover":true}}],staticClass:"iconn",staticStyle:{"color":"green"},attrs:{"icon":"pencil-square"},on:{"click":function($event){return _vm.$emit('iconClick', {
              id: _vm.idGallery,
              imageSrc: _vm.imageSrc,
              tituloNoticia: _vm.imagetitle,
              descripNoticia: _vm.imageDescription,
              action: 'editGallery',
            })}}})],1):(_vm.idGallery == 1)?_c('p',{staticClass:"hoverr-2-description text-uppercase mb-0"},[_c('b-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Galeria de imagenes'),expression:"'Galeria de imagenes'",modifiers:{"hover":true}}],staticClass:"iconn",staticStyle:{"color":"blue"},attrs:{"icon":"images"},on:{"click":function($event){return _vm.$emit('iconClick', {
              id: _vm.idGallery,
              title: _vm.imagetitle,
              action: 'openGallery',
            })}}})],1):_c('p',{staticClass:"hoverr-2-description text-uppercase mb-0"},[_c('b-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Crear Galeria'),expression:"'Crear Galeria'",modifiers:{"hover":true}}],staticClass:"iconn",staticStyle:{"color":"green"},attrs:{"icon":"pencil-square"},on:{"click":function($event){return _vm.$emit('iconClick', {
              id: _vm.idGallery,
              title: _vm.imagetitle,
              action: 'newGallery',
            })}}})],1)])])]):_c('div',{staticClass:"itemm cardViewconf",style:(`${_vm.isSelected}`),on:{"click":function($event){return _vm.$emit('selectedImage', _vm.imageSrc)}}},[_c('div',{staticClass:"hoverr hoverr-2 text-white rounded"},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":`${this.baseApi}/img/minGallery/` + _vm.imageSrc,"alt":_vm.imageDescription}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }