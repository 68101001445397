<template>
  <b-modal
    class="popupNews"
    :hide-footer="false"
    :hide-header="false"
    :id="id"
    size="xl"
    scrollable
    @show="showModal"
    @hidden="hiddenModal"
    ok-title="Guardar cambios"
    cancel-title="Salir"
    @ok="updateGallery()"
  >
  <b-overlay :show="countImagesUpdate>0" rounded="sm"></b-overlay>
    <template v-slot:modal-header>
      <div class="d-flex">
        <h2>Galeria de Imagenes</h2>
      </div>
      <div class="pointer justify-content-center">
        <b-button @click="changeImage" :pressed="false" variant="success"
          >Subir Imagenes</b-button
        >
      </div>
      <div class="pointer justify-self-center">
        <closeicon @click="closeModal()" />
      </div>
    </template>
    <div class="wrapper">
      <template v-for="(item, index) in listaGallery">
        <cardViewSelector
          :seleccion="true"
          :imageSrc="item.urlImage"
          :key="index"
          :index="item.id"
          :position="item.position"
          :imagesSelected="selectedImages"
          @selectedImage="selectedImage"
          @eliminarImagen="eliminarImagen"
        />
      </template>
      <template v-for="(item, index) in listaGallery">
        <cardViewSelector
          :seleccion="false"
          :imageSrc="item.urlImage"
          :key="index"
          :index="item.id"
          :position="item.position"
          :imagesSelected="selectedImages"
          @selectedImage="selectedImage"
          @eliminarImagen="eliminarImagen"
        />
      </template>
      <input
        accept=".jpeg, .jpg, .png"
        @change="newImage"
        id="uploadImage"
        type="file"
        style="display: none"
        multiple
      />
    </div>
  </b-modal>
</template>

<script lang="ts">
import closeicon from "../../assets/components/close.vue";
import axios from "axios";
import cardViewSelector from "@/components/dashboard/CardViewSelector.vue";

export default {
  name: "selectorImagenes",
  props: {
    dataGallery: Array,
    idGallery: String,
    multiSelect: Boolean,
    id: String,
  },

  components: {
    closeicon,
    cardViewSelector,
  },

  data() {
    return {
      countImagesUpdate: 0,
      listaGallery: [],
      selectedImages: [],
      baseApi: location.protocol + "//" + location.hostname,
    };
  },
  watch: {},
  computed: {},
  methods: {
    eliminarImagen(element) {
      console.log(element);
      alert("Eliminar imagen: " + element.src);
      this.$bvModal
        .msgBoxConfirm("¿Seguro que desea eliminar la imagen seleccionada?", {
          size: "sm",
          centered: true,
          footerClass: "justify-content-center",
          okTitle: "Si",
          cancelTitle: "No",
        })
        .then((option) => {
          if (option) {
            axios({
              method: "DELETE",
              url: `${this.baseApi}/api_armadamadrid/galeria?eliminarImagen`,
              headers: { token: this.$cookies.get("user_sessionSD") },
              data: {
                img: element.src,
                id: element.index,
              },
            })
              .then((result) => {
                console.log(result);
                this.$emit("updateGalleries");
              })
              .catch((value) => {
                console.log(value);
              });
          } else {
            console.log("Nada");
          }
        });
      this.$bvModal.hide(this.id);
    },
    hiddenModal() {
      this.listaGallery = [];
    },
    showModal() {
      this.$store.dispatch("changeAllImages");
      if (this.idGallery) {
        this.changeImages(this.idGallery);
      } else {
        this.changeImages();
        this.selectedImages = [];
        if (Array.isArray(this.dataGallery))
          this.selectedImages = this.listaGallery.filter((lista) =>
            this.dataGallery.find((element) => lista.urlImage == element)
          );
      }
    },
    updateGallery() {
      console.log();
      if (this.idGallery) {
        this.$bvModal
          .msgBoxConfirm(
            "¿Seguro que desea Modificar las imagenes de la galeria?",
            {
              size: "sm",
              centered: true,
              footerClass: "justify-content-center",
              okTitle: "Si",
              cancelTitle: "No",
            }
          )
          .then((option) => {
            if (option) {
              axios({
                method: "put",
                url: `${this.baseApi}/api_armadamadrid/galeria?actualizarimgGaleria`,
                headers: { token: this.$cookies.get("user_sessionSD") },
                data: {
                  id: this.idGallery,
                  listImages: this.selectedImages.map((element) => {
                    return element.id;
                  }),
                },
              })
                .then((result) => {
                  console.log(result);
                  // this.$emit("updateGalleries");
                })
                .catch((value) => {
                  console.log(value);
                });
            } else {
              console.log("Nada");
            }
          });
      } else {
        this.$emit("imagen", this.selectedImages);
      }
      this.$bvModal.hide(this.id);
    },
    changeImages(gallery) {
      console.log("Carga Imagenes Seleccionadas");
      this.listaGallery = this.$store.getters.getAllImages;
      if (gallery) {
        axios({
          method: "GET",
          url: `${this.baseApi}/api_armadamadrid/galeria?id=${gallery}`,
        })
          .then((value) => {
            this.selectedImages = value.data;
          })
          .catch((value) => {
            console.log(value);
          });
      } else if (this.dataGallery) {
        console.log("Carga Imagenes Seleccionadas");
      }
    },
    //Metodo para la seleccion de las imagenes
    selectedImage(value) {
      if (value.isSelected) {
        this.selectedImages = this.selectedImages.filter(
          (element) => value.url !== element.urlImage
        );
      } else {
        if (!this.multiSelect) {
          this.selectedImages = [];
        }
        this.selectedImages.push(
          this.listaGallery.find((element) => element.urlImage === value.url)
        );
      }
    },
    changeImage() {
      console.log('Metodo abrir uploadImagenes');
      document.getElementById("uploadImage").click();
    },
    closeModal() {
      this.$bvModal.hide(this.id);
    },
    //Ciclo {renderImage - canvasImage - uploadImage}
    newImage(value) {
      console.log('Metodo Subir Imagenes');
      this.countImagesUpdate = 0;
      this.countImagesUpdate = value.target.files.length;
      for (let index = 0; index < this.countImagesUpdate; index++) {
        this.renderImage(value.target.files[index]);
      }
    },
    renderImage(image) {
      console.log('Metodo Subir Imagenes - renderImage');
      let name = image.name.slice(0, image.name.indexOf("."));
      var reader = new FileReader();
      reader.readAsDataURL(image);
      let lc = this;
      reader.onload = function () {
        lc.canvasImage(reader.result, name);
      };
    },
    canvasImage(image, name) {
      // nos encargamos de hacer la miniatura de la imagen
      const img = new Image();
      img.src = image;
      let dis = this;
      img.onload = function () {
        const oc = document.createElement("canvas");
        const octx = oc.getContext("2d");
        oc.width = 350;
        oc.height = (img.height * 350) / img.width;
        octx.drawImage(img, 0, 0, oc.width, oc.height);
        oc.toBlob(
          function (blob) {
            var reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function () {
              dis.uploadImage(image, reader.result, name);
              dis.imagenPortada = reader.result;
            };
          },
          "image/jpeg",
          0.8
        );
      };
    },
    uploadImage(imageBig, imageLitel, name) {
      imageBig = imageBig.replace("data:image/jpg;base64,", "");
      imageBig = imageBig.replace("data:image/jpeg;base64,", "");
      imageBig = imageBig.replace("data:image/png;base64,", "");

      imageLitel = imageLitel.replace("data:image/jpg;base64,", "");
      imageLitel = imageLitel.replace("data:image/jpeg;base64,", "");
      imageLitel = imageLitel.replace("data:image/png;base64,", "");
      name = Math.trunc(Math.random() * (999 - 100) + 100).toString() + name;
      name = "sd" + name + ".jpg";
      name = name.replaceAll(" ", "_");
      axios({
        method: "post",
        url: `${this.baseApi}/api_armadamadrid/galeria?postImage`,
        headers: {
          token: this.$cookies.get("user_sessionSD"),
        },
        data: {
          imageLitel: imageLitel,
          imageBig: imageBig,
          nameImage: name,
        },
      })
        .then((result) => {
          console.log(result);
          this.listaGallery.unshift({
            id: result.data[0].id,
            idGroup: result.data[0].idGroup,
            urlImage: result.data[0].urlImage,
          });
          this.countImagesUpdate--;
          if (this.countImagesUpdate == 0) {
            this.$store.dispatch("changeAllImages");
          }
        })
        .catch((value) => {
          console.log(value);
        });
    },
  },
};
</script>
<style scoped>
.d-flex {
  margin: 0 auto !important;
}

.pointer{
  margin-right: 10%;
}
</style>
