<template>
  <div>
    <b-sidebar
      id="sidebar-footer"
      aria-label="Sidebar with custom footer"
      no-header
      shadow
      :visible="true"
    >
      <template #footer="{}">
        <div class="barButton bg-dark text-light align-items-center px-3 py-4">
          <b-icon
            id="buttonshow"
            class="icon"
            icon="box-arrow-right"
            @click="showSidebar = !showSidebar"
          />
        </div>
      </template>
      <div class="px-3 py-2">
        <hr class="solid" />
        <div class="divOptions">
          <p class="optionsViews" @click="cerrarSesion()">Cerrar Sesión</p>
          <b-icon
            v-b-tooltip.hover="'Cerrar Sesión'"
            class="icon iconLabel"
            icon="x-circle"
            @click="cerrarSesion()"
          />
        </div>
        <hr class="solid" />
        <div class="divOptions">
          <p class="optionsViews" @click="$emit('pag', 'noticias')">Noticias</p>
          <b-icon
            v-b-tooltip.hover="'Noticias'"
            class="icon iconLabel"
            icon="newspaper"
            @click="$emit('pag', 'noticias')"
          />
        </div>
        <hr class="solid" />
        <div class="divOptions">
          <p class="optionsViews" @click="$emit('pag', 'noticias')">Noticias Caritas</p>
          <b-icon
            v-b-tooltip.hover="'Noticias caritas'"
            class="icon iconLabel"
            icon="newspaper"
            @click="$emit('pag', 'noticiasCaritas')"
          />
        </div>
        <hr class="solid" />
        <div class="divOptions">
          <p class="optionsViews" @click="$emit('pag', 'galeria')">Galeria</p>
          <b-icon
            v-b-tooltip.hover="'Galeria'"
            class="icon iconLabel"
            icon="images"
            @click="$emit('pag', 'galeria')"
          />
        </div>
        <hr class="solid" />
        <div class="divOptions">
          <p class="optionsViews" @click="$emit('pag', 'PopUp')">PopUp</p>
          <b-icon
            v-b-tooltip.hover="'PopUp'"
            class="icon iconLabel"
            icon="bell"
            @click="$emit('pag', 'PopUp')"
          />
        </div>
        <hr class="solid" />
        <div class="divOptions">
          <p class="optionsViews" @click="$emit('pag', 'Horarios')">Horarios</p>
          <b-icon
            v-b-tooltip.hover="'Horarios'"
            class="icon iconLabel"
            icon="calendar2-date"
            @click="$emit('pag', 'Horarios')"
          />
        </div>
      </div>
    </b-sidebar>
  </div>
</template>
<script>
export default {
  name: "sidebarComp",
  props: {},
  data() {
    return {
      showSidebar: false,
    };
  },
  watch: {
    showSidebar: function (value) {
      if (value) {
        document.getElementById("sidebar-footer").style.marginLeft = "0px";
        document.getElementById("sidebar-footer").style.transition = "0.5s";
        document.getElementById("buttonshow").style.transform =
          "rotate(180deg)";
        // transform: rotate(180deg);
        let elements = document.getElementsByClassName("iconLabel");
        for (const element of elements) {
          element.style.display = "none";
          document.getElementById("bodyDashboard").style.marginLeft = "290px";
        }
      } else {
        document.getElementById("sidebar-footer").style.marginLeft = "-270px";
        document.getElementById("sidebar-footer").style.transition = "0.5s";
        let elements = document.getElementsByClassName("iconLabel");
        for (const element of elements) {
          element.style.display = "block";
          document.getElementById("bodyDashboard").style.marginLeft = "15px";
        }
        document.getElementById("buttonshow").style.transform = "rotate(0deg)";
      }
    },
  },
  mounted() {
    document.getElementById("sidebar-footer").style.marginLeft = "-270px";
  },
  methods: {
    cerrarSesion(){
      if (this.$cookies.get("user_sessionSD")) {
        this.$cookies.remove("user_sessionSD");
            this.$router.push({ name: "login"});
          }
    }
  },
};
</script>
<style>
.barButton {
  text-align: end;
}
.divOptions {
  display: flex;
}
.optionsViews {
  left: 0;
  right: 0;
  position: absolute;
}
.divOptions :hover {
  font-weight: bold;
  color: brown;
  cursor: pointer;
}
.icon {
  right: 15px;
  position: absolute;
  width: 20px;
  height: 20px;
}
.solid {
  margin-top: 30px;
}
#buttonshow:hover {
  cursor: pointer;
}
</style>
