<template>
  <div
    :key="this.$route.query.id"
    class="bodyCarrete"
    v-if="cuerpoNoticia != ''"
  >
    <h1 class="title">{{ title }}</h1>
    <div class="cuerpoNoticia mt-4" v-html="newBody" />
    <div v-if="idGallery && idGallery != 0" class="galleryButton">
      <button class="btn btn-success" @click="goGallery">
        <b-icon icon="images" />Galeria
      </button>
    </div>
    <div class="backButton">
      <img
        :src="'https://santodomingocartagena.com/img/icon/backButton.png'"
        alt="atras"
        @click="$router.go(-1)"
      />
    </div>
    <grid-fotos :listaImagenes="imagenesNoticia" />
  </div>
</template>

<script>
import axios from "axios";
import GridFotos from "@/components/galeria/GridFotos.vue";

export default {
  name: "vistaNoticia",
  metaInfo() {
    return {
      link: [
        {
          rel: "canonical",
          href: `https://parroquiaarmada.com/${this.$route.fullPath}`,
        },
      ],
      title: `Parroquia de la Armada Nuestra Señora del Carmen  - ${this.title}`,
      meta: [
        {
          name: "description",
          content: `${this.cuerpoNoticia}`,
        },
      ],
    };
  },
  props: [],
  components: {
    GridFotos,
  },
  data() {
    return {
      newBody: undefined,
      title: "",
      onlyImage: [],
      idGallery: undefined,
      cuerpoNoticia: "",
      imagePortada: "",
      baseApi: location.protocol + "//" + location.hostname,
    };
  },
  watch: {},
  methods: {
    htmlContentEdit() {
      if (this.newBody.indexOf("<img") >= 0) {
        let indice = this.newBody.indexOf("<img") + 5;
        this.newBody =
          this.newBody.slice(0, indice) +
          ' style="max-width: -webkit-fill-available;" ' +
          this.newBody.slice(indice);
      }
    },
    goGallery() {
      this.$router.push({
        name: "carrete",
        query: { id: this.idGallery, title: this.title },
      });
    },
    getnews() {
      let id = this.$route.query.id;
      this.title = this.$route.query.title;
      var query = "";

      if (id.includes("caritas")) {
        query =
          `${this.baseApi}/api_armadamadrid/noticias?idCaritas=` +
          id.split("-")[1];
      } else {
        query = `${this.baseApi}/api_armadamadrid/noticias?id=` + id;
      }

      axios
        .get(query)
        .then((data) => {
          this.title = data.data[0].titulo;
          this.idGallery = data.data[0].idgaleria;
          this.newBody = data.data[0].htmlText;
          this.cuerpoNoticia = data.data[0].prenoticia;
          this.imagePortada = data.data[0].imagenportada;
          this.fechaPublicacion = data.data[0].fechapublicacion;
          this.imagenesNoticia = data.data[0].imagenesNoticia.map((element) => {
            return {
              urlImage: element.nombreimagen,
              titulo: "",
              description: "",
            };
          });
          this.htmlContentEdit();
          this.modifyMeta();
        })
        .catch((erro) => {
          console.log(erro);
        });
    },
    modifyMeta() {
      document.querySelector('head meta[property="og:title"]').content =
        this.title;
      document.querySelector('head meta[property="og:description"]').content =
        this.cuerpoNoticia;
      document.querySelector('head meta[property="og:url"]').content =
        document.location.href;
    },
  },
  mounted() {
    this.getnews();
  },
};
</script>
<style>
.bodyCarrete {
  padding-top: 150px;
}
.backButton img {
  width: 45px;
  display: flex;
  margin-left: 20px;
  transition: 1s;
}
.backButton :hover {
  width: 55px;
  transition: 1s;
}
.backButton :hover {
  cursor: pointer;
}
.backButton {
  padding: 20px;
  font-size: 20px;
  cursor: pointer;
  position: fixed;
  bottom: 20px;
}
.galleryButton {
  padding: 20px;
  font-size: 20px;
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  right: 0;
}
.cuerpoNoticia {
  margin-left: 84px;
  margin-right: 84px;
}
</style>
<style scoped>
.bodyCarrete {
  min-height: calc(100vh) !important;
}
.cuerpoNoticia {
  max-width: 80%;
  margin: 0 auto;
}
</style>
