<template>
  <b-col>
    <b-row>
      <b-button @click="modalEditor()" variant="success">Nuevo PopUp</b-button>
    </b-row>
    <div v-if="lista.length > 0" class="wrapper">
      <!-- <template v-for="(item, index) in lista"> -->
      <cardPopUp
        v-for="(item, index) in lista"
        :imageSrc="item.src"
        :popuptitle="item.title"
        :idPopUp="item.idpopup"
        :key="index"
        @editPopUp="editarPopUp"
      />
      <!-- </template> -->
    </div>
    <editorPopUp modalId="editorPopUp" :popUpData="popUpData"/>
  </b-col>
</template>

<script>
import axios from "axios";
import editorPopUp from "@/components/dashboard/EditorPopUp.vue";
import cardPopUp from "@/components/dashboard/CardPopUp.vue";

export default {
  name: "vistaNoticia",
  props: [],
  components: {editorPopUp,cardPopUp},
  data() {
    return {
      lista: [],
      popUpData:[],


      newBody: undefined,
      title: "",
      onlyImage: [],
      idGallery: undefined,
      cuerpoNoticia: "",
      imagePortada: "",
      baseApi: location.protocol + "//" + location.hostname,
    };
  },
  watch: {},
  computed:{
  },
  methods: {
    editarPopUp(idPopUp){
      axios({
        method: "get",
        url: `${this.baseApi}/api_armadamadrid/popup?idPopUp=${idPopUp.id}`,
        headers: { token: this.$cookies.get("user_sessionSD") },
      })
        .then((data) => {
          this.popUpData =data.data;
          this.$nextTick(()=>this.$bvModal.show("editorPopUp"));
        })
        .catch((erro) => {
          console.log(erro);
        });
    },
    modalEditor(){
      this.$bvModal.show("editorPopUp");
    },
    getPopUps() {
      axios({
        method: "get",
        url: `${this.baseApi}/api_armadamadrid/popup`,
        headers: { token: this.$cookies.get("user_sessionSD") },
      })
        .then((data) => {
          this.lista = data.data;
        })
        .catch((erro) => {
          console.log(erro);
        });
    },
  },
  mounted() {
    this.getPopUps();
  },
};
</script>
<style scoped>

.bodyCarrete {
  padding-top: 120px;
}
.backButton img {
  width: 45px;
  display: flex;
  margin-left: 20px;
  transition: 1s;
}
.backButton :hover {
  width: 55px;
  transition: 1s;
}
.backButton :hover {
  cursor: pointer;
}
.backButton {
  padding: 20px;
  font-size: 20px;
  cursor: pointer;
  position: fixed;
  bottom: 20px;
}
.galleryButton {
  padding: 20px;
  font-size: 20px;
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  right: 0;
}
.cuerpoNoticia {
  margin-left: 84px;
  margin-right: 84px;
}
</style>
