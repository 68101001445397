import { render, staticRenderFns } from "./EditorHorarios.vue?vue&type=template&id=64e3a763&scoped=true"
import script from "./EditorHorarios.vue?vue&type=script&lang=ts"
export * from "./EditorHorarios.vue?vue&type=script&lang=ts"
import style0 from "./EditorHorarios.vue?vue&type=style&index=0&id=64e3a763&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64e3a763",
  null
  
)

export default component.exports